<template>
  <div class="login-wrapper">
    <div class="container" style="height: auto!important">
      <div class="header">
        <div class="header-image">
          <div class="header-image-particle header-image-particle-1"></div>
          <div class="header-image-particle header-image-particle-2"></div>
          <div class="header-image-particle header-image-particle-3"></div>
          <svg viewBox="0 0 1000 1000">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
              <path
                  d="M2901.7,4280.3c-1274.3-463.7-2048.5-755-2075.3-779.9c-80.5-74.7-99.7-139.9-128.4-448.4c-172.5-1849.2-9.6-3437.8,482.9-4691.1c210.8-538.5,527-1086.5,858.5-1494.7c298.9-367.9,760.8-776.1,1159.4-1025.2c553.8-346.8,1337.6-617,1791.7-620.9c486.8-3.8,1339.5,302.8,1916.3,687.9c550,367.9,1046.3,881.5,1420,1469.8C9187.2-1263.6,9524.4,689.2,9302.1,3052c-28.8,308.5-47.9,373.7-128.4,448.4C9118.2,3554,5078.6,5020,4994.3,5020C4959.8,5018.1,4018.9,4686.6,2901.7,4280.3z M6878,3705.4l1810.9-657.3l23-274c61.3-737.8,51.7-1690.2-24.9-2343.6c-191.6-1684.4-812.5-2977.9-1807.1-3779c-492.5-394.7-1145.9-701.4-1711.3-801c-182.1-32.6-189.7-32.6-390.9,7.7c-1293.5,260.6-2380,1203.4-2964.5,2569.8c-276,649.6-442.7,1368.2-534.7,2309.1c-32.6,331.5-28.7,1646.1,5.8,2027.4l26.8,283.6l1839.6,668.8c1167,423.5,1853.1,665,1878,657.3C5049.9,4368.5,5881.5,4067.6,6878,3705.4z"/>
              <path
                  d="M4430.9,2691.7c-298.9-80.5-532.7-297-642-596c-36.4-103.5-42.2-159-49.8-663l-7.7-551.9h-86.2c-47.9,0-122.6-9.6-166.7-23c-105.4-30.7-264.5-189.7-295.1-295.1c-32.6-113.1-32.6-2010.2,0-2123.3c30.7-105.4,189.7-264.5,295.1-295.1c113.1-32.6,2930-32.6,3043.1,0C6627-1825,6786-1666,6816.7-1560.6c32.6,113.1,32.6,2010.2,0,2123.3C6786,668.1,6627,827.1,6521.6,857.8c-44.1,13.4-118.8,23-166.7,23h-86.2l-7.7,551.9c-7.7,504-13.4,559.6-49.8,663c-111.1,302.8-344.9,517.4-649.6,596C5410.1,2731.9,4576.5,2730,4430.9,2691.7z M5513.6,2053.6c122.7-88.2,128.4-113.1,134.1-668.8l5.7-504H5002h-653.5V1356c1.9,576.8,17.2,640.1,178.2,720.5c51.7,26.8,128.4,30.7,492.5,26.8C5437,2097.6,5452.3,2095.7,5513.6,2053.6z M5210.8,202.4c199.3-132.2,228-415.8,55.6-588.3l-74.7-74.7v-371.8c0-444.6,3.8-433.1-191.6-433.1c-195.5,0-191.6-11.5-191.6,433.1v371.8l-74.7,74.7c-153.3,153.3-155.2,385.2-1.9,538.5c42.2,42.2,103.5,84.3,138,93.9C4971.3,277.2,5128.4,258,5210.8,202.4z"/>
            </g>
          </svg>
        </div>

        <h1 class="header-title text-center">
          Oppss!! 404!
        </h1>

        <p class="text text-center">
          Sayfa Bulunamadı
        </p>

        <button type="button" class="button app-login-button" @click="$router.push({name: 'home'})">
          Anasayfaya Dön
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoAccess'
}
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --purple-100: #ede9fe;
  --purple-200: #c4b5fd;
  --purple-600: #7c3aed;
  --purple-800: #5b21b6;
  --pink-100: #fee2e2;
  --pink-200: #fecaca;
  --grey-100: #f3f4f6;
  --grey-200: #e5e7eb;
  --grey-300: #d1d5db;
  --grey-400: #9ca3af;
  --grey-500: #6b7280;
  --grey-700: #374151;
  --white: #fff;
}

.login-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  display: flex;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--grey-100);
  font-family: "Poppins", sans-serif;
  color: var(--grey-700);
}

.container {
  top: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 660px;
  border-radius: 24px;
  padding: 24px;
  background-color: var(--white);
  overflow: hidden;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 10%),
  0 2px 4px -1px rgb(0 0 0 / 6%);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title {
  font-size: 22px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 8px;
}

.header-image {
  height: 80px;
  width: 80px;
  margin: 20px 40px 60px 40px;
}

.header-image svg {
  position: relative;
  fill: var(--purple-600);
}

.header-image-particle {
  position: absolute;
  border-radius: 50%;
  background-color: var(--pink-200);
  opacity: 0.8;
}

.header-image-particle-1 {
  height: 22px;
  width: 22px;
  animation: particle-1 1.2s ease-in-out alternate infinite;
}

@keyframes particle-1 {
  0% {
    transform: translate3d(80px, 65px, 0);
  }
  100% {
    transform: translate3d(88px, 73px, 0);
  }
}

.header-image-particle-2 {
  height: 18px;
  width: 18px;
  animation: particle-2 1.4s ease-in-out alternate infinite;
}

@keyframes particle-2 {
  0% {
    transform: translate3d(-50px, 40px, 0);
  }
  100% {
    transform: translate3d(-30px, 40px, 0);
  }
}

.header-image-particle-3 {
  height: 12px;
  width: 12px;
  animation: particle-3 1.8s ease-in-out alternate infinite;
}

@keyframes particle-3 {
  0% {
    transform: translate3d(90px, 10px, 0);
  }
  100% {
    transform: translate3d(105px, -5px, 0);
  }
}

.button {
  // width: 100%;
  padding: 16px 16px;
  // margin: 16px 0;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  background-color: var(--purple-600);
  -webkit-appearance: none;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 200ms, color 200ms;
}

.button:active {
  background-color: var(--purple-800);
}

.link {
  color: var(--purple-600);
  font-weight: 500;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.text {
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 16px;
}

.text:not(.link) {
  color: var(--grey-500);
}

.text-center {
  text-align: center;
}

.modal,
.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
}

.modal-active.modal {
  pointer-events: auto;
}

.modal-backdrop {
  opacity: 0;
  background-color: rgba(8, 2, 29, 0.5);
  transform: translateZ(0);
  transition: opacity 200ms;
}

.modal-active .modal-backdrop {
  opacity: 1;
}

.modal-body {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  width: 100%;
  padding: 24px;
  background: var(--white);
  transform: translateY(100%);
  transition: transform 220ms cubic-bezier(0.33, 1, 0.68, 1);
}

.modal-body::before {
  content: "";
  position: absolute;
  top: 14px;
  height: 4px;
  margin: 0 auto;
  width: 60px;
  border-radius: 2px;
  background-color: var(--grey-200);
}

.modal-active .modal-body {
  transform: translateY(0);
}

.modal-title {
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 20px;
}

.settings-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.settings-button {
  min-width: 72px;
  padding: 10px 16px;
  margin: 16px 0;
  border-radius: 16px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  color: var(--grey-500);
  background-color: var(--grey-100);
  -webkit-appearance: none;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 200ms, color 200ms;
}

.settings-button-active {
  background-color: var(--pink-100);
  color: var(--purple-600);
}

.settings-button-icon {
  font-size: 40px;
  text-align: center;
  color: var(--grey-300);
  transform: translate3d(0, 0, 0);
  transition: color 200ms;
}

.settings-button-icon-active {
  color: var(--purple-600);
  transform-origin: center center;
  animation: bounce 500ms ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }
  10% {
    transform: scale3d(1, 0.8, 1) translate3d(0, 0, 0);
  }
  40% {
    transform: scale3d(1, 1, 1) translate3d(0, -12px, 0);
  }
  70% {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }
  100% {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }
}

.input-login {
  position: relative;
  margin: 16px 0;
  font-size: 16px;
}

.input-login label {
  position: absolute;
  top: 16px;
  left: 20px;
  font-weight: 500;
  color: var(--grey-400);
  pointer-events: none;
  transform-origin: top left;
  transition: transform 200ms;
}

.input-login input {
  padding: 24px 20px 14px 20px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 8px;
  background: var(--grey-100);
  transition: background-color 160ms;
}

.input-login input:focus {
  background: var(--grey-200);
}

.input-active label {
  transform: scale(0.7, 0.7) translate(0, -10px);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}

.footer .text {
  margin: 0;
}

</style>
